<template>
    <div class="all-container">
        <canvas ref="canvas" class="ice"></canvas>
        <!-- <img  src="@/assets/ice.png" alt="ice"> -->
        <img ref="ldh" src="@/assets/ldh.png" alt="Ldh">
        <h1>{{ per }}</h1>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { HolidayUtil, LunarYear } from 'lunar-javascript';

const canvas = ref(null);
const ldh = ref(null);
const per = ref(0)
onMounted(() => {
    const percent = getPercent()
    per.value = Math.floor(percent * 100) + '%'
    ldh.value.onload = () => {
        const ice = new Image();
        ice.src = require('@/assets/ice.png');
        // resize canvas
        canvas.value.width = ldh.value.width * 1.2
        canvas.value.height = ldh.value.height * 1.2 * (1 - percent)
        canvas.value.style.marginTop = ldh.value.height * 1.2 * percent + 'px'
        canvas.value.style.transform = `translate(-50%, ${-1 * ldh.value.height * 0.6}px)`;
        ice.onload = () => {
            const ctx = canvas.value.getContext('2d');
            ctx.drawImage(ice, 0, percent * ice.height, ice.width, (1 - percent) * ice.height, 0, 0, canvas.value.width, canvas.value.height);
        };
    }
});

const getPercent = () => {
  // 获取所有日子
  let today = new Date()
  let lunarYear = LunarYear.fromYear(today.getFullYear());
  const allDays = lunarYear.getDayCount()
  // 获取还有多少天到春节
  let i = 1
  let isSpring = false
  while (!isSpring){
    today.setDate(today.getDate() + 1)
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1)
    const day = String(today.getDate())
    const isHoliday = HolidayUtil.getHoliday(year, Number(month), Number(day))
    if (isHoliday && isHoliday.getName() === '春节' || getBuding(year, month, day)) {
      isSpring = true
    } else if (i > 400) {
        isSpring = true
    }
    i++
  }
  console.log('今年一共' + allDays + '天，距离春节还有' + i + '天', '解冻', (allDays - i) / allDays)
  return Math.min(1, (allDays - i) / allDays)
}

const getBuding = (year, month, day)=> {
    const springFestivales = [
        [2026, 2, 17],
        [2027, 2, 6],
        [2028, 1, 26]
    ]
    return springFestivales.some(festival => {
        return festival[0] === Number(year) && festival[1] === Number(month) && festival[2] === Number(day)
    })
}

</script>

<style scoped>
.all-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    object-fit: contain;
    z-index: -1;
    filter: blur(1px);

    h1 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: #fff;
        text-shadow: #85aed1ce 0px 0px 4px;
    }

    img {
        max-width: 80%;
        max-height: 80%;
    }
    .ice {
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: .6;
    }
}
</style>