<template>
  <div :class="{'dark-mode': darkMode}">
    <div class="blog-page-container" v-if="userLogined">
      <LeftOutlined @click="handleJump" style="transform: translateX(-14px);" :style="darkMode ? 'color: #fff' : ''" class="home_icon"/>
      <!--树形折叠导航栏，可折叠，管理员可编辑-->
      <OtherBlogMenuCom class="blog-menu-com"/>
      <!--滚动页面，包括文章属性、博客区（md组件）、评论区-->
      <div class="scroll-page">
        <OtherBlogContentCom ref="blogContent" class="blog-content-com"/>
      </div>
    </div>
    <div class="unlogined-container" v-else>
      <div>
        <a-input style="width: 80%; margin: 10px 0" v-model:value="user" placeholder="请输入用户名"/>
        <a-input style="width: 80%; margin: 10px 0" v-model:value="password" type="password" placeholder="请输入密码"/>
        <a-button type="primary" @click="checkUser">登录</a-button>
        <span>* 账号为您的姓名，初始密码为1234，登陆后可修改密码。</span>
        <span>* 若没有账号，可用 账号guest; 密码guest 登录体验</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  LeftOutlined,
} from '@ant-design/icons-vue';
import {jumphelper} from "@/js/jumphelper";

import {onMounted, ref} from "vue";
import {checkUserPassword} from "@/js/apihelper";
import {message} from "ant-design-vue";
import OtherBlogMenuCom from "@/coms/blog/otherblog/OtherBlogMenuCom.vue";
import OtherBlogContentCom from "@/coms/blog/otherblog/OtherBlogContentCom.vue";
import { postLog } from "@/js/apihelper";


const darkMode = ref(false);
const blogContent = ref(null);
const handleJump = ()=>{
  // 管理员可编辑，退出前保存
  blogContent.value.saveDocDetail();
  jumphelper.jumpToMyBlog()
}

onMounted(()=>{
  darkMode.value = localStorage.getItem("darkMode") === "true";
  user.value = localStorage.getItem('user')
  if(user.value){
    userLogined.value = true
  }
  postLog('访问-桌面共享工作区')
})

const password = ref(null)
const user = ref(null)
const userLogined = ref(false)
const checkUser = ()=>{
  const getResult = checkUserPassword(user.value, password.value)
  getResult.then(response=>{
    if (response.data){
      message.success('登陆成功，用户已存储')
      localStorage.setItem('user', user.value)
      localStorage.setItem('token', response.data)
      if (user.value === '时啟轩107080') {
        localStorage.setItem('managerSecrets', 'nzt100years')
      }
      setTimeout(()=>{
        userLogined.value = true
      },400)
    }
    else{
      message.warn('用户不存在，请联系我增加权限～')
    }
  }).catch(e=>{
    console.log(e)
    message.error('用户不存在，请联系我增加权限～')
  })
}

</script>

<style scoped>
  .unlogined-container{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .unlogined-container div{
    width: 300px;
    height: auto;
    box-shadow: 0 0 8px #00000022;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    button {
      width: 80%;
      margin: 16px 0;
    }

    span {
      color: #777;
      font-size: 12px;
      width: 80%;
      margin-top: 8px;
      white-space: pre-line;
      word-break: keep-all;
    }
  }
  .unlogined-container div input{
    width: calc(100% - 120px);
    margin-right: 12px;
  }
  .blog-page-container{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: top-fade-in .6s 1;
  }
  .blog-menu-com{
    height: 100%;
  }
  .scroll-page{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fdfdfd;
  }

  .dark-mode {
    .unlogined-container{
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .unlogined-container div{
      width: 300px;
      height: auto;
      box-shadow: 0 0 8px #00000022;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;

      button {
        width: 80%;
        margin: 16px 0;
      }

      span {
        color: #777;
        font-size: 12px;
        width: 80%;
        margin-top: 8px;
        white-space: pre-line;
        word-break: keep-all;
      }
    }
    .unlogined-container div input{
      width: calc(100% - 120px);
      margin-right: 12px;
    }
    .blog-page-container{
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      animation: top-fade-in .6s 1;
    }
    .blog-menu-com{
      height: 100%;
    }
    .scroll-page{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      position: relative;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: #232323;
    }
  }


</style>
