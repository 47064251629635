<script setup>
  import Vditor from 'vditor'
  import 'vditor/dist/index.css';
  import {onBeforeUnmount, onMounted, ref, watch, defineExpose} from "vue";
  import {bus} from "vue3-eventbus";
  import {message} from "ant-design-vue";
  import {changePasswordDeatail, getDocDetailApiOther, updateDocDetailOther} from "@/js/apihelper";
  import ClockCom from '../ClockCom.vue';
  import { useRoute } from 'vue-router'
  import {postLog} from "@/js/apihelper";

  const route = useRoute()
  const darkMode = ref(false)

  const contentLoaded = ref(false)
  const selectNodeHandler = async (nodeKey) => {
      await saveDocDetail();
      contentLoaded.value = false;
      getDocDetail(nodeKey);
  };

  const clearUser = () =>{
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('managerSecret')
  }

  const user = ref(null)
  onMounted( async ()=>{
    darkMode.value = localStorage.getItem('darkMode') === 'true'
    window.addEventListener('keydown', handleKeydown);
    postLog('访问-共享空间')
    user.value = localStorage.getItem('user')
    vditor.value = new Vditor('vditor',options.value)
    // 首次加载时检查并调用 selectNodeHandler
    const initialSelectedKey = route.query.selectedKey;
    if (initialSelectedKey) {
        selectNodeHandler(initialSelectedKey);
    }
    watch(() => route.query.selectedKey, (newSelectedKey) => {
      if (newSelectedKey) {
        selectNodeHandler(newSelectedKey); // 调用处理函数
      }
    });
    window.addEventListener('beforeunload', async (event) => {
      if (!route.path.includes('otherblog')) {
        return
      }
      await saveDocDetail();
      event.returnValue = '文件已保存';
    });
  })

  const handleKeydown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key ==='s') {
      if (!route.path.includes('otherblog')) {
        return
      }
      event.preventDefault();
      saveDocDetail(false);
    }
  }

  onBeforeUnmount(()=>{
    bus.off('selectNode')
    vditor.value.destroy()
    window.removeEventListener('keydown', handleKeydown)
  })

  /**
   * 获取详细内容
   */
  const docNodeKey = ref()
  const getDocDetail = (nodeKey)=>{
    //编辑器未加载完成，延迟获取
    if(!loaded.value){
      setTimeout(()=>{
        getDocDetail(nodeKey)
      },500)
      return
    }
    //获取
    const contentResult = getDocDetailApiOther(nodeKey, user.value)
    contentResult.then(response=>{
      if(response.data.blogKey){
        docNodeKey.value = response.data.blogKey
        if(isManager){
          vditor.value.setValue(decodeURIComponent(response.data.content))
          contentLoaded.value = true
        }else{
          Vditor.preview(document.getElementById(`vditor`), decodeURIComponent(response.data.content), {});
          document.getElementById(`vditor`).style.padding = '0 24px'
        }
      }
    })
  }

  /**
   * 保存内容
   */
  const saveDocDetail = (setContent = true)=>{
    const copyValue = JSON.parse(JSON.stringify(vditor.value.getValue()))
    try {
      if(!vditor.value || vditor.value.getValue() === '加载中，请稍等~' || !contentLoaded.value){
        return
      }
      if (setContent) {
        vditor.value.setValue('加载中，请稍等~')
      }
      const postResult = updateDocDetailOther({
        currentKey: docNodeKey.value,
        newContent: encodeURIComponent(copyValue),
        user: user.value
      })
      postResult.then(response=>{
        if(response.data){
          // message.success('保存成功')
          if (!setContent) {
            message.success('保存成功')
          }
          return
        }
        // message.error('保存失败')
      })
    } catch (error) {
      console.log(error)
    }
  }

  defineExpose({
    saveDocDetail,
  })


  /**
   * 获取当前文本内容
   */
  const getContent = ()=>{
    const tmp = "加载中，请稍等~"
    if(isManager){
      vditor.value.setValue(tmp)
    }else{
      Vditor.preview(document.getElementById(`vditor`), tmp, {});
      document.getElementById(`vditor`).style.padding = '0 24px'
    }
    loaded.value = true
    if (darkMode.value){
      vditor.value.setTheme('dark', 'dark')
    }
  }

  /**
   * 编辑器设置
   */
  const isManager = true
  const vditor = ref()
  const loaded = ref(false)
  const options = ref({
    toolbar:[
       'upload', 'link', 'record', 'export', '|' ,
       'line' , 'quote', '|',
       'bold', 'italic', 'strike', '|',
       'table' , 'list', 'ordered-list', 'check' , 'code' , '|' ,
       'edit-mode', 'outline'
    ],
    preview:{
      maxWidth: 1400
    },
    toolbarConfig:{
      pin: true,
      hide: false
    },
    cache: {
      enable: false
    },
    after: getContent,
    value: 'loading...',
    upload: {
      url: 'http://www.fivecheers.com:1001/file/blogUpLoadFile'
    },
    counter: {
      enable: true
    },
    mode: 'wysiwyg',
  })

  const showModal = ref(false)
  const form = ref({
    newPassword: '',
    confirmPassword: '',
  })
  const changePassword = ()=>{
    showModal.value = true
  }
  const changePasswordConfirm = ()=>{
    if (localStorage.getItem('user') === 'guest'){
      message.error('游客无法修改密码')
      return
    }
    if (!form.value.newPassword || !form.value.confirmPassword){
      message.error('密码不能为空')
      return
    }
    if (form.value.newPassword!== form.value.confirmPassword) {
      message.error('两次输入的密码不一致，请重新输入')
      return
    }
    // 存在风险
    const getResult = changePasswordDeatail(localStorage.getItem('user'), form.value.newPassword)
    getResult.then(response=>{
      if(response.data){
        message.success('修改成功，下个凌晨3点后生效！')
        showModal.value = false
        return
      }
      message.error('修改失败')
    }).catch(err=>{
      console.log(err)
      message.error('修改失败')
    })
  }


</script>

<template>
  <div class="content-container" :class="{ 'dark-mode': darkMode }">
    <!--内容-->
    <div class="content-self" id="vditor">加载中，请稍等~</div>
    <div class="a-container">
      <a @click="changePassword" href="#">修改密码</a>
      <a href="https://ld246.com/guide/markdown">教程</a>
      <a @click="clearUser" href="/">退出登录</a>
    </div>
    <!-- <a-button size="small" @click="saveDocDetail" v-if="isManager" type="default">保存</a-button> -->
    <ClockCom/>
    <a-modal
      v-model:visible="showModal"
      title="修改密码"
      width="400px"
      :footer="null"
    >
      <a-form :model="form" ref="formRef" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
        <a-form-item label="新密码" name="newPassword">
          <a-input type="password" v-model:value="form.newPassword" />
        </a-form-item>
        <a-form-item label="确认密码" name="confirmPassword">
          <a-input type="password" v-model:value="form.confirmPassword" />
        </a-form-item>
      </a-form>
      <a-button style="width: 100%" type="primary" @click="changePasswordConfirm">修改</a-button>
    </a-modal>

  </div>
</template>

<style scoped>
  .content-container{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .content-container .a-container{
    position: fixed;
    right: 48px;
    top: 8px;
    z-index: 999;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .content-container .a-container a{
    color: #91aefa;
    margin-left: 16px;
  }
  .content-container button{
    position: fixed;
    right: 16px;
    top: 44px;
    z-index: 999;
  }
  .content-container:deep(.vditor-reset){
    padding: 0 16px;
  }
  .content-container:deep(.hljs){
    background: #fdfdfd !important;
  }
  .content-container .content-self{
    min-height: 90vh;
    border: 0;
    position: relative;
    z-index: 999;
  }
  .content-container ::v-deep(.vditor-toolbar) {
    padding: 0;
    line-height: 0;
    border-radius: 0;
    border: 0;
    background: linear-gradient(to right bottom, #edf0fc88, #faf3f988);
    backdrop-filter: blur(4px);
  }
  .dark-mode .content-container ::v-deep(.vditor-toolbar) {
    padding: 0;
    line-height: 0;
    border-radius: 0;
    border: 0;
    /* background: linear-gradient(to right bottom, #edf0fc88, #faf3f988); */
    background: #454545;
    backdrop-filter: blur(4px);
  }
  .dark-mode .content-container ::v-deep(.content-self) {
    color: #fff;
  }
  .dark-mode .content-container ::v-deep(table) {
    color: #000;
  }
  .dark-mode .content-container ::v-deep(th) {
    background: #f0f0f0;
  }
  .dark-mode .content-container ::v-deep(tr) {
    background: #fdfdfd;
  }
  .dark-mode .content-container ::v-deep(td) {
    background: #fff;
  }
</style>
