import { v4 as uuidv4 } from 'uuid';
import {checkManagerApi, getIpAddress} from "@/js/apihelper";

export const timeCorrect = (wrongTime)=>{
    {
        const utcDate = new Date(wrongTime);
        const options = {
            timeZone: 'Asia/Shanghai',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        };
        const formattedDate = new Intl.DateTimeFormat('zh-CN', options)
            .formatToParts(utcDate)
            .map(({ type, value }) => (type === 'literal' && value === '-' ? ' ' : value))
            .join('');
        return formattedDate;
    }
}

export const dateToYYYYMMDD = (date)=> {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1; // 月份从 0 开始，所以需要加 1
    const day = d.getDate();
    return year * 10000 + month * 100 + day;
}

export const checkAmIDead = (getTime)=>{
    const targetDate = new Date(getTime);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - targetDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    if (daysDifference <= 365) {
        return false;
    } else {
        return true;
    }
}

export const checkManager = async ()=>{
    try {
        const response = await checkManagerApi();
        if (response.data) {
            return localStorage.getItem('managerSecret') && localStorage.getItem('managerSecret') === 'nzt100years';
        }
        return false;
    } catch (e) {
        console.log('检查管理员权限' + e);
        return false;
    }
}

// utils/debounce.js
export function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

export const initAuth = ()=>{
    //获取UUID，ip，time
    const UUID = localStorage.getItem('UUID')
    const ipAddress = localStorage.getItem('ipAddress')
    const visitTime = localStorage.getItem('visitTime')
    //没有UUID则赋予UUID
    if (!UUID){
        localStorage.setItem('UUID',uuidv4())
    }
    //没有ipAddress则赋予ipAddress
    if (!ipAddress){
        setIpAddress()
    }
    //没有visitTime则赋予visitTime
    if (!visitTime){
        setVisitTime()
    }
    //visitTime过期则更新ipAddress和visitTime
    else if (visitTime !== getNowTime()){
        setVisitTime()
        setIpAddress()
    }
}

const setIpAddress = ()=>{
    const postResult = getIpAddress()
    postResult.then(response=>{
        const ipAddress =
            'location:'+response.data.country+response.data.province+response.data.area+response.data.city+
            ' ip:'+response.data.ip+
            ' isp:'+response.data.isp
        localStorage.setItem('ipAddress',ipAddress)
    }).catch(e=>{
        console.log('获取用户ip出错'+e)
    })
}

const setVisitTime = ()=>{
    localStorage.setItem('visitTime',getNowTime())
}

const getNowTime = ()=>{
    const date = new Date()
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2,'0')
    const day = String(date.getDate()).padStart(2, '0')
    const nowTime = `${year}-${month}-${day}`
    return nowTime
}

export function calculateDateDifference(startDate, endDate) {
    // 将日期字符串转换为 Date 对象
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    let count = 0;
  
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      const day = d.getDay();
      if (day !== 0 && day !== 6) { // 排除周六（6）和周日（0）
        count++;
      }
    }
  
    return count;
  }