<script setup>
import SelectCardCom from "@/coms/select/SelectCardCom.vue";
import {jumphelper} from "@/js/jumphelper";
import BackgroundCom from "@/coms/select/BackgroundCom.vue";
import {LeftOutlined, QuestionCircleOutlined} from "@ant-design/icons-vue";
import {checkManager} from "@/js/jshelper";
// import { postLog } from "@/js/apihelper";
import { onMounted, ref, onBeforeUnmount } from "vue";
import * as PIXI from "pixi.js";
import { Live2DModel } from "pixi-live2d-display";

window.PIXI = PIXI;
let app
let model
const liveCanvas = ref(null)
const liveCanvasWrapper = ref(null)

onMounted(async ()=>{
  // postLog('访问-手机选择页')
  app = new PIXI.Application({
    view: liveCanvas.value,
    backgroundAlpha: 0,
  })

  const canvasWidth = liveCanvasWrapper.value.clientWidth
  const canvasHeight = liveCanvasWrapper.value.clientHeight

  app.renderer.resize(canvasWidth, canvasHeight)
  
  model = await Live2DModel.from("/models/miside/3.model3.json")
  
  app.stage.addChild(model)

  model.scale.set(0.2)
  model.anchor.set(0.5, 0)
  model.position.set(canvasWidth / 2, 10)

  // model.on('tap', () => {
  //   console.log('tap')
  //   chatMsg.value = '开发中，敬请期待！'
  // });

  liveCanvas.value.style.pointerEvents = 'none';
})

onBeforeUnmount(()=>{
  app?.destroy()
  model?.destroy()
})

const chatTimeout = ref(null)

const clickModel = () => {
  chatMsg.value = '开发中，敬请期待！'
  if (chatTimeout.value) {
    clearTimeout(chatTimeout.value)
    chatTimeout.value = null
  }
  chatTimeout.value = setTimeout(() => {
    chatMsg.value = ''
    console.log('clearTimeout')
  }, 3000)
}

const chatMsg = ref('')

const beforeJumpToManager = async ()=>{
  const managerResult = await checkManager()
  if(managerResult){
    jumphelper.jumpToManagerSelect()
  }else{
    jumphelper.jumpToManagerPassword()
  }
}

const showModal = () => {
  openModal.value = true
}
const openModal = ref(false)

</script>

<template>
  <div>
    <BackgroundCom/>
    <SelectCardCom class="select_card_pos"/>
    <div ref="liveCanvasWrapper" class="live_canvas" >
      <canvas ref="liveCanvas"></canvas>
    </div>
    <div @touchend="clickModel" class="chat-box-wrapper">
      <div :style="{ 'opacity': chatMsg.length > 0 ? 1 : 0 }" class="chat-box">
        {{ chatMsg }}
      </div>
    </div>
    <a-button @click="beforeJumpToManager" class="manager_btn" type="text" size="small">管理入口</a-button>
    <LeftOutlined style="color: #ffffff; margin-left: -8px" @click="jumphelper.jumpToHello()" class="home_icon"/>
    <QuestionCircleOutlined @click="showModal" class="question_icon" />
    <a-modal v-model:open="openModal" title="说明">
      <template #footer>
        <div class="modal-footer">米塔live2d素材来自B站up主三缘绘工坊，仅个人娱乐使用~</div>
      </template>
    </a-modal>
  </div>
</template>

<style scoped>
.select_card_pos{
  position: fixed;
  left: 50%;
  top: 300px;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 480px;
}
.manager_btn{
  position: fixed;
  right: 12px;
  top: 16px;
  color: #fff;
  letter-spacing: 2px;
  animation: right-fade-in 1 .8s;
}

.live_canvas{
  position: fixed;
  bottom: 0;
  left: 40px;
  width: calc(100vw - 80px);
  height: calc(100vh - 470px);
  background-color: transparent;
  pointer-events: none;

  canvas {
    pointer-events: none;
  }
}

.chat-box-wrapper {
  position: fixed;
  bottom: 0;
  left: 72px;
  right: 72px;
  height: calc(100vh - 510px);
  z-index: 999;
}

.chat-box {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  height: 64px;
  z-index: 9999;
  background-color: #c78be288;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.question_icon {
  z-index: 999999;
  position: fixed;
  right: 12px;
  bottom: 6px;
  color: #fff;
}

.modal-footer {
  margin-bottom: 12px;
  text-align: start;
}

</style>
