<script setup>
  import Vditor from 'vditor'
  import 'vditor/dist/index.css';
  import {onBeforeUnmount, onMounted, ref, watch, defineExpose} from "vue";
  import {checkManager} from "@/js/jshelper";
  import {bus} from "vue3-eventbus";
  import {getDocDetailApi, updateDocDetail, postLog} from "@/js/apihelper";
  import {message} from "ant-design-vue";
  import AiHelperCom from "@/coms/blog/AiHelperCom.vue";
  import { useRoute } from 'vue-router'

  const route = useRoute()


  const contentLoaded = ref(false)
  const selectNodeHandler = async (nodeKey) => {
      saveDocDetail();
      contentLoaded.value = false;
      getDocDetail(nodeKey);
  };

  onMounted(async () => {
    window.addEventListener('keydown', handleKeydown);
      isManager.value = await checkManager();
      vditor.value = new Vditor('vditor', options.value);
      // 首次加载时检查并调用 selectNodeHandler
      const initialSelectedKey = route.query.selectedKey;
      if (initialSelectedKey) {
          selectNodeHandler(initialSelectedKey);
      }
      watch(() => route.query.selectedKey, (newSelectedKey) => {
        if (newSelectedKey) {
          selectNodeHandler(newSelectedKey); // 调用处理函数
          postLog('查看博客'+ newSelectedKey)
        }
      });
      window.addEventListener('beforeunload', async (event) => {
        if (!isManager.value) {
          return
        }
        if (!route.path.includes('blog')) {
          return
        }
        saveDocDetail();
        event.returnValue = '文件已保存';
      });
  });

  const handleKeydown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key ==='s') {
      if (!isManager.value) {
        return
      }
      if (!route.path.includes('blog')) {
        return
      }
      event.preventDefault();
      saveDocDetail(false);
    }
  }

  onBeforeUnmount(()=>{
    bus.off('selectNode', selectNodeHandler)
    vditor.value.destroy()
    window.removeEventListener('keydown', handleKeydown)
  })

  /**
   * 获取详细内容
   */
  const docNodeKey = ref()
  const getDocDetail = (nodeKey)=>{
    //编辑器未加载完成，延迟获取
    if(!loaded.value){
      setTimeout(()=>{
        getDocDetail(nodeKey)
      },500)
      return
    }
    // 非自身preview
    if (!isManager.value) {
      Vditor.preview(document.getElementById(`vditor`), '加载中，请稍等~', {});
      document.getElementById(`vditor`).style.padding = '0 24px'
    }
    //获取
    const contentResult = getDocDetailApi(nodeKey)
    contentResult.then(response=>{
      if(response.data.blogKey){
        docNodeKey.value = response.data.blogKey
        if(isManager.value ){
          vditor.value.setValue(decodeURIComponent(response.data.content))
          contentLoaded.value = true
        }else{
          Vditor.preview(document.getElementById(`vditor`), decodeURIComponent(response.data.content), {});
          document.getElementById(`vditor`).style.padding = '0 24px'
        }
      }
    })
  }

  /**
   * 保存内容
   */
  const saveDocDetail = (setContent = true)=>{
    const copyValue = JSON.parse(JSON.stringify(vditor.value.getValue()))
    try{
      if(!vditor.value || vditor.value.getValue() === '加载中，请稍等~' || !isManager.value || !contentLoaded.value){
        return
      }
      if (setContent) {
        vditor.value.setValue('加载中，请稍等~')
      }
      const postResult = updateDocDetail({
        currentKey: docNodeKey.value,
        newContent: encodeURIComponent(copyValue)
      })
      postResult.then(response=>{
        if(response.data){
          // message.success('保存成功')
          if (!setContent) {
            message.success('保存成功')
          }
          console.log('保存成功')
          return
        }
        // message.error('保存失败')
      })
    }catch(e){
      console.log(e)
    }
  }

  defineExpose({
    saveDocDetail,
  })

  /**
   * 获取当前文本内容
   */
  const getContent = ()=>{
    const tmp = "加载中，请稍等~"
    if(isManager.value){
      vditor.value.setValue(tmp)
    }else{
      Vditor.preview(document.getElementById(`vditor`), tmp, {});
      document.getElementById(`vditor`).style.padding = '0 24px'
    }
    loaded.value = true
    vditor.value.setTheme('dark', 'dark')
  }

  /**
   * 编辑器设置
   */
  const isManager = ref(false)
  const vditor = ref()
  const loaded = ref(false)
  const options = ref({
    toolbar:[
       'upload', 'link', 'record', 'export', '|' ,
       'line' , 'quote', '|',
       'bold', 'italic', 'strike', '|',
       'table' , 'list', 'ordered-list', 'check' , 'code' , '|' ,
       'edit-mode', 'outline'
    ],
    preview:{
      maxWidth: 1400
    },
    toolbarConfig:{
      pin: true,
      hide: false
    },
    cache: {
      enable: false
    },
    after: getContent,
    value: '加载中，请稍等~',
    upload: {
      url: 'http://www.fivecheers.com:1001/file/blogUpLoadFile'
    },
    counter: {
      enable: true
    },
    mode: 'wysiwyg',
  })

</script>

<template>
  <div class="content-ai-container">
    <!--内容-->
    <div class="content-container">
      <div class="content-self" id="vditor">加载中，请稍等~</div>
      <div class="a-container">
        <!-- <a href="/otherblog" >other users</a>
        <a href="https://github.com/Vanessa219/vditor">supported by Vditor</a> -->
      </div>
      <!-- <a-button size="small" @click="saveDocDetail" v-if="isManager" type="default">保存</a-button> -->
    </div>
    <!--AI-->
    <AiHelperCom :doc-from-father="vditor" v-if="isManager" class="ai-container"/>
  </div>
</template>

<style scoped>
  .content-ai-container{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .content-container{
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #232323;
  }
  .content-container .a-container{
    position: absolute;
    right: 28px;
    top: 8px;
    z-index: 999;
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .content-container .a-container a{
    color: #91aefa;
    margin-left: 16px;
  }
  .content-container button{
    position: absolute;
    right: 16px;
    top: 44px;
    z-index: 999;
  }
  .content-container:deep(.vditor-reset){
    padding: 0 16px;
  }
  .content-container:deep(.hljs){
    background: #fdfdfd !important;
  }
  .content-container .content-self{
    min-height: 90vh;
    border: 0;
    position: relative;
    z-index: 999;
  }
  .content-container .content-self:deep(img){
    max-width: 800px;
    min-width: 200px;
    display: block;
    margin: 0 auto;
  }
  .content-container ::v-deep(.vditor-toolbar) {
    padding: 0;
    line-height: 0;
    border-radius: 0;
    border: 0;
    /*background: linear-gradient(to right bottom, #edf0fc88, #faf3f988); */
    background-color: #454545;
    backdrop-filter: blur(4px);
    color: #fff;
  }
  .content-container ::v-deep(.content-self) {
    color: #fff;
  }
  .content-container ::v-deep(table) {
    color: #000;
  }
  .content-container ::v-deep(th) {
    background: #f0f0f0;
  }
  .content-container ::v-deep(tr) {
    background: #fdfdfd;
  }
  .content-container ::v-deep(td) {
    background: #fff;
  }
</style>
