<script setup>
import {
  HomeOutlined
} from '@ant-design/icons-vue';
import {jumphelper} from "@/js/jumphelper";
import {postLog, getAllFoodsApi, addNewFoodApi, deleteFoodApi} from "@/js/apihelper";
import {onMounted, ref} from "vue";
import DefaultBackgroundColorful from '@/coms/all/DefaultBackgroundColorful.vue';
import {checkManager} from '@/js/jshelper'
import {message} from 'ant-design-vue'


onMounted(() => {
  postLog('访问-吃什么')
  Promise.all([getAydenFoods(), getLocalFoods(), getIsManager()]).then(() => {
    loading.value = false
    mode.value = managerResult.value? 0 : 1
    if (!managerResult.value && foods.value[1].length === 0) {
        message.warning('先点击下方按钮，添加你想吃的食物。')
        message.warning('添加后会在本地保存，放心使用吧！')
    }
  })
  updatePositions()
})

const getIsManager = async ()=>{
  managerResult.value = await checkManager()
}

const loading = ref(true)

const getAydenFoods = () => {
    const getRes = getAllFoodsApi()
    getRes.then(res => {
        foods.value[0] = res.data
        console.log(foods.value, 'food')
    }).catch(err => {
        message.error('获取Ayden的食谱失败')
        console.log(err)
    })
}

const getLocalFoods = () => {
    const localFoods = JSON.parse(localStorage.getItem('foods')) || []
    foods.value[1] = localFoods
}

// TODO
const mode = ref(0)
const changeMode = () => {
    mode.value = mode.value === 0 ? 1 : 0
}
const foods = ref([[], []])

// TODO
const openModal = ref(false)
const modalTitle = ref('皇上今天吃什么')
const handleOk = () => {
  openModal.value = false
}
const openEditModal = (title) => {
  modalTitle.value = title
  openModal.value = true
}

const enableAddFood = () => {
     addFood.value = true
}
const addFood = ref(false)
const addFoodName = ref('')
const addFoodConfirm = () => {
    if (addFoodName.value.trim() === '') {
        message.error('请输入食物名称')
        return
    }
    if (mode.value === 0) {
        // ayden 新增食物
        const getRes = addNewFoodApi(addFoodName.value)
        getRes.then(res => {
            if (res.data) {
                getAydenFoods()
                addFoodName.value = ''
                addFood.value = false
                return
            }
            message.success('新增食物失败')
        }).catch(err => {
            message.error('新增食物失败')
            console.log(err)
        })
    } else if (mode.value === 1) {
        // 本地新增食物
        let currentFoods = JSON.parse(localStorage.getItem('foods')) || []
        currentFoods.push({
            foodName: addFoodName.value,
            foodId: Date.now()
        })
        localStorage.setItem('foods', JSON.stringify(currentFoods))
        getLocalFoods()
        addFoodName.value = ''
        addFood.value = false
    }
}

const deleteAydenFood = (foodId) => {
    const getRes = deleteFoodApi(foodId)
    getRes.then(res => {
        if (res.data) {
            getAydenFoods()
            return
        }
        message.success('删除食物失败')
    }).catch(err => {
        message.error('删除食物失败')
        console.log(err)
    })
}

const deleteLocalFood = (foodId) => {
    let currentFoods = JSON.parse(localStorage.getItem('foods')) || []
    currentFoods = currentFoods.filter(food => food.foodId !== foodId)
    localStorage.setItem('foods', JSON.stringify(currentFoods))
    getLocalFoods()
}

// 获取随机位置和浮动效果
const getRandomPosition = () => {
  const maxWidth = window.innerWidth;
  const maxHeight = window.innerHeight;

  // 随机位置
  const top = Math.random() * maxHeight;
  const left = Math.random() * maxWidth;


  return {
    top: `${top}px`,
    left: `${left}px`,
  };
};

const getRandomBgColor = () => {
  const scale = 1 + (Math.random() * 0.6 - 0.3); // 随机缩放，略微浮动
  const r = Math.floor(Math.random() * 106) + 150;  // 限制红色在 150 到 255 之间
  const g = Math.floor(Math.random() * 106) + 150;  // 限制绿色在 150 到 255 之间
  const b = Math.floor(Math.random() * 106) + 150;  // 限制蓝色在 150 到 255 之间
  return {
    backgroundColor: `rgba(${r}, ${g}, ${b}, 1)`,
    transform: `scale(${scale}); translate(-50%, -50%)`,
  }
};

const frameCount = ref(0);
const randomCount = ref(0)
const startRandom = ref(false)
const updatePositions = () => {
    if (startRandom.value) {
        randomCount.value = Math.floor(Math.random() * 1000)
    }
    if (frameCount.value % 10 === 0 && startRandom.value) {
        foods.value[mode.value].forEach((food, index) => {
            const foodItem = document.getElementById(`food-${index}`);
            if (foodItem) {
            const position = getRandomPosition(index);
            foodItem.style.top = position.top;
            foodItem.style.left = position.left;
            }
        });
        console.log(randomCount.value, 'random')
        const final = document.getElementById(`final`);
        if (final) {
            final.style.color = getRandomBgColor().backgroundColor
        }
    }
    frameCount.value++;
    requestAnimationFrame(updatePositions);
};

const startRandomFood = () => {
    if (chance.value === 5) {
        message.warn('不想吃别吃了！')
        chance.value++
        return
    }
    else if (chance.value === 6) {
        message.warn('你再点！')
        chance.value++
        return
    }
    else if (chance.value === 7) {
        message.success('服了你了，快选吧!')
    }
    startRandom.value = true
    chance.value++
}

const endRandomFood = () => {
    startRandom.value = false
}

const chance = ref(0)

const managerResult = ref(false)

</script>

<template>
  <div class="plan_container">
    <HomeOutlined style="color: #fff;" @click="jumphelper.jumpToSelect()" class="home_icon"/>
    <DefaultBackgroundColorful/>
    <!-- 选项 -->
    <div class="main-container">
        <span :style="{ opacity: startRandom ? 1 : 0 }" :id="'food-' + index" v-for="(food, index) in foods[mode]" :key="index" class="food-item">
            {{ food.foodName }}
        </span>
    </div>
    <!-- 转盘按钮 -->
    <div class="btn-container">
        <span style="color: #fff;">{{ mode === 0 ? 'Ayden\'s' : '我的' }}</span>
        <a-button @click="changeMode">切菜单</a-button>
        <a-button v-if="mode === 0 && managerResult" @click="openEditModal('Ayden今天吃什么')">编辑</a-button>
        <a-button v-else-if="mode === 1" @click="openEditModal('皇上今天吃什么')">编辑</a-button>
    </div>
    <div class="center-container">
        <h1>今天吃</h1>
        <h2 v-if="chance > 0" id="final">{{ foods[mode][randomCount%foods[mode].length]?.foodName || '' }}</h2>
        <a-button :disabled="loading || foods[mode].length === 0" v-if="!startRandom" @click="startRandomFood" type="primary" style="width: 100px;">{{ chance === 0 ? '开始' : '再来一次！' }}</a-button>
        <a-button v-else @click="endRandomFood" style="width: 80px;">停!</a-button>
    </div>
    <!-- 编辑 -->
    <a-modal ok-text="确认" cancel-text="取消" v-model:visible="openModal" :title="modalTitle" @ok="handleOk">
      <template v-if="mode === 0">
        <div class="scroll-container">
            <div v-for="(food, index) in foods[0]" :key="index" class="food-item">
                <span>{{ food.foodName }}</span>
                <a-button @click="deleteAydenFood(food.foodId)" type="text" danger>删除</a-button>
            </div>
            <div v-if="addFood" style="margin-top: 12px;">
                <a-input v-model:value="addFoodName" placeholder="请输入新的食物名称" />
                <a-button style="padding: 0 6px; margin-left: 8px;" type="primary" @click="addFoodConfirm">添加</a-button>
            </div>
            <a-button class="add-btn" type="primary" @click="enableAddFood">新增</a-button>
        </div>
      </template>
      <template v-if="mode === 1">
        <div class="scroll-container">
            <div v-for="(food, index) in foods[1]" :key="index" class="food-item">
                <span>{{ food.foodName }}</span>
                <a-button @click="deleteLocalFood(food.foodId)" type="text" danger>删除</a-button>
            </div>
            <div v-if="addFood" style="margin-top: 12px;">
                <a-input v-model:value="addFoodName" placeholder="请输入新的食物名称" />
                <a-button style="padding: 0 6px; margin-left: 8px;" type="primary" @click="addFoodConfirm">添加</a-button>
            </div>
            <a-button class="add-btn" type="primary" @click="enableAddFood">新增</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<style scoped>

    .main-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 100px;

        .food-item {
            color: #eeeeeeaa;
            white-space: nowrap;
            font-size: 22px;
            padding: 8px 12px;
            border-radius: 8px;
            position: absolute;
        }
    }
    .btn-container {
        position: fixed;
        left: 50%;
        bottom: 36px;
        transform: translate(-50%, 0);
        width: 90%;
        height: 60px;
        max-width: 320px;
        background-color: #ffffff22;
        border-radius: 8px;
        backdrop-filter: blur(10px);
        min-width: 280px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        transition: all 0.3s ease-in-out;
    }
    .center-container {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        color: #fff;
        align-items: center;
        padding: 12px;
        backdrop-filter: blur(2px);
        border-radius: 8px;
        width: 100%;

        h1 {
            margin: 0;
            font-size: 20px;
            margin-bottom: 8px;
        }

        h2 {
            font-size: 34px;
            font-weight: bold;
            margin: 8px 0 16px 0;
        }
    }
    .scroll-container {
        width: 100%;
        height: 360px;
        overflow-y: scroll;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 4px;

        div {
            border-bottom: 1px solid #eee;
            width: 100%;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #215ecf;
            margin: 4px 0;
            font-size: 17px;
        }

        button {
            margin: 0;
            padding: 0;
        }

        .add-btn {
            margin-top: 24px;
            width: 100%;
            padding: 4px;
        }

    }

</style>