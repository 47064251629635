<script setup>
import {
  HomeOutlined,
} from '@ant-design/icons-vue';
import {jumphelper} from "@/js/jumphelper";
import {onMounted, ref} from "vue";
import {getAntsByDay, postLog} from "@/js/apihelper";
import DefaultBackground from '@/coms/all/DefaultBackground.vue';
import {getBingImgApi} from "@/js/apihelper";
import {message} from "ant-design-vue";
import { HolidayUtil, Lunar, Solar, SolarUtil, SolarWeek } from 'lunar-javascript';
import { checkManager, dateToYYYYMMDD, calculateDateDifference } from '@/js/jshelper';
import LdhCom from '@/coms/fish/LdhCom.vue';

onMounted(() => {
  postLog('访问-手机今日摸鱼')
  getBingImg()
  getYiJi()
  initToday()
  getTodos()
  getFishDays()
  getPoem()
})

const isManager = ref(false)
// bing每日图片
const imgUrl = ref(null)
const imgTitle = ref(null)
const getBingImg = async () => {
  const res = await getBingImgApi()
  console.log(res)
  const url = res.data.images[0].url
  imgUrl.value = 'https://cn.bing.com' + url
  const title = res.data.images[0].copyright
  imgTitle.value = title
}
const showTitle = () => {
  message.info(imgTitle.value)
}

// 每日宜忌
const yi = ref('')
const ji = ref('')
const getYiJi = () => {
  const d = Lunar.fromDate(new Date());
  yi.value = d.getDayYi().join(' | ');
  ji.value = d.getDayJi().join(' | ');
}

// 今日
const todayDay = ref('')
const todayMonth = ref('')
const todayYear = ref('')
const todayWeek = ref('')
const todayWeekIndex = ref(0)
const thisYearDays = ref(0)
const todayYearIndex = ref(0)
const lunarString = ref('')
const holidays = ref('')
const weather = ref('')
const initToday = () => {
  const d = Solar.fromDate(new Date());
  const w = SolarWeek.fromDate(new Date(), 0);
  const lunarD = Lunar.fromDate(new Date());
  const holiD = Solar.fromYmd(d.getYear(), d.getMonth(), d.getDay())
  const holiDL = Lunar.fromYmd(lunarD.getYear(), lunarD.getMonth(), lunarD.getDay())
  todayDay.value = d.getDay()
  todayMonth.value = d.getMonth()
  todayYear.value = d.getYear()
  todayWeek.value = d.getWeekInChinese()
  todayWeekIndex.value = w.getIndex()
  thisYearDays.value = SolarUtil.getDaysOfYear(d.getYear())
  todayYearIndex.value = SolarUtil.getDaysInYear(d.getYear(), d.getMonth(), d.getDay())
  lunarString.value = lunarD.toString()
  let hol = holiD.getFestivals()
  const lunarHol = holiDL.getFestivals()
  hol.push(...lunarHol)
  for (let h of hol) {
    h = '- ' + h + ' -'
  }
  holidays.value = hol.map(h => {return '- ' + h + ' -'}).join('\n')
}

// TODO
const todos = ref([])
const getTodos = async () => {
  isManager.value = await checkManager()
  if (isManager.value) {
    const getRes = getAntsByDay(dateToYYYYMMDD(new Date()))
    getRes.then(res => {
      todos.value = res.data
    }).catch(err => {
      console.log(err)
      message.error('获取TODO失败')
    })
  }
}

// 摸鱼
const fishDays = ref([
  
])
const getFishDays = () => {
  const d = Solar.fromDate(new Date());
  // 获取下个周末要多久
  fishDays.value.push({
    name: '周末',
    days: (12 - d.getWeek()) % 7
  })

  let today = new Date()
  let i = 1
  while (fishDays.value.length < 4){
    today.setDate(today.getDate() + 1)
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1)
    const day = String(today.getDate())
    const isHoliday = HolidayUtil.getHoliday(year, month, day)
    if (isHoliday && !isHoliday.isWork() && !(
      fishDays.value.some(f => f.name === isHoliday.getName())
    )) {
      fishDays.value.push({
        name: isHoliday.getName(),
        days: i - 1
      })
    } else if (i > 300) {
      break
    }
    i++
  }
}

// 今日诗词
const poem = ref('')
const origin = ref('')
const getPoem = () => {
  const jinrishici = require('jinrishici');
  jinrishici.load(result => {
    console.log(result.data)
    poem.value = result.data.content;
    const author = result.data.origin.author
    const dynasty = result.data.origin.dynasty
    const title = result.data.origin.title
    origin.value = `${title} | ${dynasty} - ${author}`
  });
}
const getInfo = () => {
  message.info(origin.value)
}

</script>

<template>
  <div>
    <DefaultBackground v-if="!imgUrl"/>
    <img class="bg_img" v-else :src="imgUrl" alt="bing每日图片"/>
    <HomeOutlined @click="jumphelper.jumpToSelect()" style="color: #fff;" class="home_icon"/>
    <!-- 今日图片 -->
    <div class="fish_img top-ani">
      <img @click="showTitle" :src="imgUrl" alt="今日摸鱼"/>
    </div>
    <div class="cards-container">
      <div style="flex: 1;" class="cards-line">
        <!-- 今日宜忌与星座运势 -->
         <div class="card glass row left-ani" style="flex: 0.4;">
           <h1>宜忌</h1>
          <span class="yi"><strong></strong> {{ yi }}</span>
          <span class="ji"><strong></strong> {{ ji }}</span>
         </div>
         <!-- 阳历&农历&星期几&天气&时间 -->
         <div class="card glass column right-ani" style="flex: 1;">
          <h1>今日</h1>
          <div class="week-line">
            <span>本月第 <strong>{{ todayWeekIndex }}</strong> 周</span>
            <span>星期{{ todayWeek }}</span>
            <span>{{ weather }}</span>
          </div>
          <div class="date-line">
            <span>{{ todayMonth }}</span>
            <span>月</span>
            <span>{{ todayDay }}</span>
            <span>日</span>
          </div>
          <div class="lunar-line">
            {{ lunarString }}
          </div>
          <div class="holiday-line">
            <span>{{ holidays }}</span>
          </div>
          <div class="year-line">
            <span>{{ todayYear }}年已过</span>
            <span> {{ todayYearIndex }} / {{ thisYearDays }} </span>
            <span>天</span>
          </div>
        </div>
      </div>
      <div style="flex: .84;" class="cards-line">
        <!-- 今日TODO -->
        <div class="card glass column left-ani" style="flex: 1; padding: 16px 8px;">
          <h1>TODO</h1>
          <template v-if="todos.length > 0">
            <div v-for="(item, index) in todos" :key="index" class="todo-line" :class="item.status === '完成' ? 'finished-line' : ''">
              <span @click="message.info(item.antTitle)">{{ item.antTitle }}</span>
              <span>{{ calculateDateDifference(new Date(), item.endDate) }}天</span>
            </div>
            <a-button type="primary" style="margin-top: auto;width: 100%; opacity: .6;" @click="jumphelper.jumpToAnt()">跳转Ant</a-button>
          </template>
        </div>
        <div class="card column right-ani" style="flex: 0.8; padding: 0;">
          <!-- 摸鱼 -->
          <div class="card-item glass column" style="flex: 1; margin-bottom: 8px;">
            <LdhCom class="ldh"/>
            <div style="margin-right: auto;">距离:</div>
            <div v-for="(item, index) in fishDays" :key="index" class="fish-line">
              <span>{{ item.name }}</span>
              <span>{{ item.days }}天</span>
            </div>
            <div style="flex: .8;"></div>
          </div>
          <!-- 吃什么 -->
          <div @click="jumphelper.jumpToFood()" class="right-ani card-item glass column eat-btn" style="height: 40px;">
            吃什么
          </div>
        </div>
      </div>
      <!-- 今日小诗 -->
      <div style="height: 48px;" class="cards-line bottom-ani">
        <div class="card glass column" @click="getInfo" style="flex: 1; color: #173392;">
            {{ poem }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "@/css/animation.css";
.bg_img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(4px) brightness(0.5);
}

.fish_img {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  height: calc((100vw - 16px) * 0.5625);
  background-color: #eee;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cards-container {
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 0;
  top: calc((100vw - 16px) * 0.5625 + 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .cards-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    position: relative;
    color: #232323;

    .card {
      height: 100%;
      margin-right: 8px;

      .card-item {
        width: 100%;
      }

      .eat-btn {
        cursor: pointer;
        background: linear-gradient(to right bottom, #ff7f50aa, #eff599aa);
        color: #fff;
        letter-spacing: 4px;
        font-weight: bold;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 16px 4px;

      span {
        margin: 0 4px;
      }

      div {
        margin: 2px 0;
      }

      .week-line {
        color: #173392;
        strong {
          color: #c50808;
        }
      }

      .date-line {
        height: 84px;
        margin: -4px 0 -12px 0;
        position: relative;

        span:nth-child(1) {
          font-size: 24px;
          font-weight: bold;
          color: #db9224;
        }
        
        span:nth-child(3) {
          font-size: 64px;
          font-weight: bold;
          color: #db9224;
        }
      }

      .lunar-line {
        color: #555;
        font-size: 14px;
      }

      .holiday-line {
        color: #db9224;
        text-align: center;
        margin-top: 4px;
        span {
          width: 50vw;
          white-space: pre-line;
        }
      }

      .year-line {
        margin-top: 4px;
        
        font-size: 14px;
        color: #173392;
        span:nth-child(2) {
          font-weight: bold;
          color: #c50808;
        }
      }
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      padding: 4px;

      span {
        writing-mode: vertical-lr;
        text-align: start;
        word-break: keep-all;
        height: calc(34vh - 36px);
        line-height: 24px;
        font-size: 14px;
      }
      .yi {
        margin-top: 36px;
        position: relative;
        margin-bottom: auto;
      }
      .yi ::before{
          position: absolute;
          left: 50%;
          top: -27px;
          content: "宜";
          color: #c56308;
          font-size: 16px;
          transform: translateX(-50%);
          z-index: 999;
        }
      .ji {
        margin-top: 36px;
        margin-bottom: auto;
        position: relative;
      }
      .ji ::before{
          position: absolute;
          left: 50%;
          top: -27px;
          content: "忌";
          color: #0815c5;
          font-size: 16px;
          transform: translate(-50%, 0);
          z-index: 999;
        }
    }

    .glass {
      background-color: #ffffffaa;
      backdrop-filter: blur(6px);
      border-radius: 8px;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    }

    h1 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      color: #6877a7;
      filter: opacity(0.5);
      margin: 0;
      padding: 0;
      z-index: -1;
      filter: blur(3px);
      font-size: 40px;
    }
  }
  .todo-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 4px 0 !important;
    border-radius: 8px;
    padding: 6px 8px;
    background-color: #db9224aa;
    color: #fff;
    position: relative;

    span {
      max-width: 36vw;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span:nth-child(2){
      color: #c50808;
    }
  }
  .finished-line {
    background-color: #49df49aa !important;
    color: #fff;

    span {
      color: #fff !important;
    }
  }
  .fish-line {
    width: 100%;
    margin: 6px 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    span:nth-child(2) {
      color: #c56308;
    }
  }
}

.left-ani {
  animation: left-fade-in 0.5s;
}
.right-ani {
  animation: right-fade-in 0.5s;
}
.bottom-ani {
  animation: bottom-fade-in 0.5s;
}
.top-ani {
  animation: top-fade-in 0.5s;
}
.ldh {
  width: 100%;
  height: 100%;
  position: absolute;
}

</style>