<script setup>
import {
  HomeOutlined,
} from '@ant-design/icons-vue';
import {addBlogUserApi, deleteBlogUserApi, getAllBlogUsersApi, refreshDeadTime} from '@/js/apihelper'
import {jumphelper} from "@/js/jumphelper";
import {onMounted, onUnmounted, ref} from 'vue';
import SelectDefaultCom from "@/coms/select/SelectDefaultCom.vue";
import {bus} from "vue3-eventbus";
import MeChangeCom from "@/coms/manager/MeChangeCom.vue";
import PlanInsertCom from "@/coms/manager/PlanInsertCom.vue";
import PlanChangeCom from "@/coms/manager/PlanChangeCom.vue";
import SpaceInsertCom from "@/coms/manager/SpaceInsertCom.vue";
import SpaceUpdateCom from "@/coms/manager/SpaceUpdateCom.vue";
import {message} from "ant-design-vue";
import UpdateDead from '@/coms/manager/UpdateDead.vue';
const activeKey = ref([]);
onMounted(()=>{
  initAllBlogUsers()
  bus.on('closeSelectCard',()=>{
    Object.keys(comsOn.value).forEach(key => {
      comsOn.value[key] = false;
    });
  })
})
onUnmounted(()=>{
  bus.off('closeSelectCard')
})
const selectCom = (comName)=>{
  switch (comName){
    case 'default': comsOn.value.default = true;
      break;
    case 'meChange': comsOn.value.meChange = true;
      break;
    case 'planInsert': comsOn.value.planInsert = true;
      break;
    case 'planChange': comsOn.value.planChange = true;
      break;
    case 'spaceInsert': comsOn.value.spaceInsert = true;
      break;
    case 'spaceUpdate': comsOn.value.spaceUpdate = true;
      break;
    case 'updateDead': comsOn.value.updateDead = true;
      break;
  }
}
const comsOn = ref({
  default:false,
  meChange: false,
  planInsert: false,
  planChange: false,
  spaceInsert: false,
  spaceUpdate: false,
  updateDead: false,
})

const imAlive = ()=>{
  const postResult = refreshDeadTime()
  postResult.then(response=>{
    if (response.data){
      message.success('更新成功')
    }
  })
}

const logOut = ()=>{
  localStorage.removeItem('managerSecret')
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  message.success('已退出登录')
}

const addNewBlogUser = ()=>{
  showNewBlogUserModal.value = true
}
const showNewBlogUserModal = ref(false)
const newBlogUser = ref('')
const handleAddBlogUser = () => {
  if (!newBlogUser.value || newBlogUser.value.trim() === ''){
    message.error('用户名不能为空')
    return
  }
  const getResult = addBlogUserApi(newBlogUser.value)
  getResult.then(response=>{
    if (response.data){
      message.success('添加成功，凌晨3点后生效！')
      showNewBlogUserModal.value = false
      newBlogUser.value = ''
    }
  }).catch(err=>{
    console.log(err)
    message.error('添加失败')
  })
}

const allBlogUsers = ref([])
const initAllBlogUsers = ()=>{
  const getResult = getAllBlogUsersApi()
  getResult.then(response=>{
    if (response.data){
      allBlogUsers.value = response.data
      return
    }
    message.error('获取失败')
  }).catch(err=>{
    console.log(err)
    message.error('获取失败')
  })
}

const showDeleteBlogUserModal = ref(false)
const deleteBlogUser = ()=>{
  showDeleteBlogUserModal.value = true
}

const deleteBlogUserClick = (account)=>{
  if (account === 'guest' || account === '时啟轩107080'){
    message.error('不能删除默认用户')
    return
  }
  const getResult = deleteBlogUserApi(account)
  getResult.then(response=>{
    if (response.data){
      message.success('删除成功')
      initAllBlogUsers()
      return
    }
    message.error('删除失败')
  }).catch(err=>{
    console.log(err)
    message.error('删除失败')
  })
}

</script>

<template>
  <div class="manager_select_background">
    <HomeOutlined @click="jumphelper.jumpToSelect()" class="home_icon"/>
    <h3 class="manager_select_head">选择功能</h3>
    <div class="manager_select_container">
      <a-collapse v-model:activeKey="activeKey" accordion>
        <a-collapse-panel key="1" header="关于我">
          <div class="manager_select_panel">
            <a-button @click="selectCom('meChange')">修改资料</a-button>
            <a-button @click="selectCom('default')">default</a-button>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="2" header="我的空间">
          <div class="manager_select_panel">
            <a-button @click="selectCom('spaceInsert')">发布说说</a-button>
            <a-button @click="selectCom('spaceUpdate')">编辑说说</a-button>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="3" header="人生逆旅">
          <div class="manager_select_panel">
            <a-button @click="selectCom('planInsert')">增加年度挑战</a-button>
            <a-button @click="selectCom('planChange')">编辑年度挑战</a-button>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="4" header="遗书与藏宝图">
          <div class="manager_select_panel">
            <a-button @click="selectCom('updateDead')">修改遗书与藏宝图</a-button>
            <a-button @click="imAlive()">我TM没死</a-button>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="5" header="Blog管理">
          <div class="manager_select_panel">
            <a-button @click="addNewBlogUser">增加Blog用户</a-button>
            <a-button @click="deleteBlogUser">删除Blog用户</a-button>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="6" header="退出登录">
          <div class="manager_select_panel">
            <a-button @click="logOut">退出登录</a-button>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <transition name="fade">
      <MeChangeCom v-if="comsOn.meChange"/>
    </transition>
    <transition name="fade">
      <SelectDefaultCom v-if="comsOn.default"/>
    </transition>
    <transition name="fade">
      <PlanInsertCom v-if="comsOn.planInsert"/>
    </transition>
    <transition name="fade">
      <PlanChangeCom v-if="comsOn.planChange"/>
    </transition>
    <transition name="fade">
      <SpaceInsertCom v-if="comsOn.spaceInsert"/>
    </transition>
    <transition name="fade">
      <SpaceUpdateCom v-if="comsOn.spaceUpdate"/>
    </transition>
    <transition name="fade">
      <UpdateDead v-if="comsOn.updateDead"/>
    </transition>
    <a-modal  title="增加Blog用户" v-model:visible="showNewBlogUserModal" @ok="handleAddBlogUser">
      <div>
        <a-input placeholder="请输入用户名" v-model:value="newBlogUser"></a-input>
      </div>
    </a-modal>
    <a-modal footer="" title="删除Blog用户" v-model:visible="showDeleteBlogUserModal">
      <div class="scroll-container">
        <div v-for="(user, index) in allBlogUsers" :key="index">
          <span style="flex: 1;">{{user.keyAccount}}</span>
          <span style="width: 100px;">帖子数：{{user.blog_count}}</span>
          <a-button style="color: red;" type="text" @click="deleteBlogUserClick(user.keyAccount)">删除</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<style scoped>
.manager_select_background{
  background: linear-gradient(to right bottom, #f7fafcaa, #eef5fcaa, #f7ecfaaa);
}
.manager_select_background_img{
  position: fixed;
  bottom: 72px;
  right: 36px;
  width: 60%;
  opacity: .1;
  animation: cheer .8s 1;
}
@keyframes cheer {
  0%{
    transform: rotateZ(25deg) scale(.4) translate(300%, 50%);
  }
}
.manager_select_head{
  position: fixed;
  left: 50%;
  top: 14px;
  height: 40px;
  transform: translate(-50%, 0);
  letter-spacing: 4px;
}
.manager_select_container{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 12px);
  animation: right-fade-in .4s 1;
  max-width: 600px;
}
.manager_select_panel{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* 左右铺开 */
}
.manager_select_panel button{
  margin: 4px 0;
}

.scroll-container {
  height: 420px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    border-bottom: 1px solid #ccc;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
  }
}
</style>
